canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.video {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  height: 100%;
  width: 80%;
  cursor: pointer;
}

.file-drop > .file-drop-target {
  /* basic styles */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;

  /* horizontally and vertically center all content */
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;

  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;

  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;

  text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  border-style: solid;
  background-color: rgba(0, 0, 0, 0.05);
}

.file-drop > .file-drop-target {
  height: 100%;
  width: 100%;
  border-color: black;
  border-style: dashed;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
}
